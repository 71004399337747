import React, { useRef, useState, useEffect, Fragment } from "react";
import {
  Table,
  Pagination,
  Badge,
  Dropdown,
  Spinner,
  Modal,
  Button,
  Row,
  Col,
  Form,
  ListGroup,
} from "react-bootstrap";

import PageTitle from "../../layouts/PageTitle";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from "../../../services/AxiosInstance";
import swal from "sweetalert";

import { format } from "date-fns";

import { getProfile } from "../../../store/actions/AuthActions";

import messageTypeList from "../../../utils/message-type";

import { CSVLink } from "react-csv";

import DTable from "../Table";
import { batch } from "react-redux";
import { be } from "date-fns/locale";

import Select from "react-select";

import stateData from "../../../utils/states.json";

import PhoneInput from "react-phone-number-input";

import { exportToExcel } from "react-json-to-excel";

import moment from "moment";
import "moment-timezone";

//const Orders = () => {
const Acknowledgement = ({ props, history }) => {
  const dispatch = useDispatch();

  const [devices, setDevices] = useState([]);
  const [acks, setAcks] = useState([]);
  const [loading, setLoading] = useState(false);

  const profile = useSelector((state) => state.auth.profile);
  const [activePage, setActivePage] = useState(0);
  let errorsObj = { instance: "", phone: "", message: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [xlsData, setXlsData] = useState([]);

  // Data Table Start
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = useState(100);
  const pageSizes = [10, 25, 50, 100];
  const [maxPage, setMaxPage] = useState(1);

  //search
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);


  const handlePageChange = (value) => {
    setPage(value);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(0);
  };

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        loadAcks();
      }, 1000)
    );

    return () => clearTimeout(searchTimeout);
  }, [search]);


  useEffect(() => {
    loadAcks();
  }, [page, pageSize]);

  useEffect(() => {
    dispatch(getProfile());
    loadAcks();
    loadDevices();
  }, [dispatch]);

 
  useEffect(() => {
    if (acks) {
      setLoading(false);
    }
  }, [acks]);

  const loadDevices = async () => {
    const { data } = await axiosInstance.post("admin/devices", {
      PAGE: 0,
      LIMIT: 9999999,
      SEARCH: "",
      DATA:false,
    });

    if (data.status) {
      setDevices(data.devices);
    } else {
      setDevices([]);
    }
  };

  const loadAcks = async () => {
    const { data } = await axiosInstance.post("admin/device-acks", {
      page: page,
      limit: pageSize,
      search: search,
    });

    if (data.status) {
      var maxPage2 = Math.ceil(data.total / pageSize);
      console.log("max page - " + maxPage2);
      setMaxPage(maxPage2);
      setLoading(false);
      setAcks(data.acks);
    }
  };

  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  const columns = [


    {
      Header: "No",
      accessor: "",
      sort: false,
      accessor: "",

      Cell: (row) => {
        return <div>{Number(row.row.id) + page * pageSize + 1}</div>;
      },
    },

    {
      Header: "S_NO",
      accessor: "S_NO",
      sort: false,
      Cell: ({ row }) => {
        const d = row.original;

        const device = devices.find((x) => x.S_NO == d.S_NO);

        return <Link to={device?"/device/"+device.id:"#"} >{d.S_NO}</Link>;
        
      },
    },

    {
      Header: "TIMESTAMP",
      accessor: "TIMESTAMP",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.TIMESTAMP
          ? moment(new Date(d.TIMESTAMP)).utc().format("DD-MM-YYYY hh:mm A") //format(new Date(d.TIMESTAMP), "dd/MM/yyyy H:mma",{ timeZone: 'Asia/Calcutta' })
          : "No";
      },
    },

    {
      Header: "MOBILE",
      accessor: "MOBILE",
      sort: false,
    
    },

  
    {
      Header: "INFO",
      accessor: "INFO",
      sort: false,
    
    },

   
  ];

  const onExport = async () => {
    const { data } = await axiosInstance.post("admin/device-acks", {
      page: 0,
      limit: 9999999999,
      search: "",
    });

    if (data.status) {
      const report = data.acks.map((x, i) => {
        var data = x;
        data.TIMESTAMP = moment(new Date(x.TIMESTAMP))
          .utc()
          .format("DD-MM-YYYY hh:mm A");
        return data;
      });

      exportToExcel(
        report,
        `Acknowledgement-${moment().format("DD-MM-YYYY h:mm")}.csv`
      );
    }
  };

  return (
    <>
    

      <Fragment>
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div class="mr-auto">
                <h4 className="card-title">Acknowledgement</h4>
              </div>

              <div class="col-md-6 mb-2">
                <div className="input-group search-area mr-2">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (searchTimeout) {
                        clearTimeout(searchTimeout);
                      }
                      loadAcks();
                    }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here"
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </form>
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <svg
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                          fill="#A4A4A4"
                        />
                      </svg>
                    </span>
                  </div>

                

                </div>


               

              
              </div>


              <Button
                    className="btn btn-sm btn-primary ml-2"
                    onClick={() => onExport()}
                  >
                    Export
                  </Button>

            </div>
            <div className="card-body">
              {loading ? (
                <div className="text-center mt-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : acks.length == 0 ? (
                <p>No Acknowledgement Found</p>
              ) : (
                <DTable
                  columns={columns}
                  data={acks}
                  pageSize={pageSize}
                  isSortable={true}
                  pagination={false}
                  pageSizes={pageSizes}
                  page={page}
                  maxPage={maxPage}
                  handlePageChange={handlePageChange}
                  handlePageSizeChange={handlePageSizeChange}
                />
              )}
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default Acknowledgement;
