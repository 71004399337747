import React, { useState } from 'react'
/// React router dom
import {Switch, Route } from 'react-router-dom'
/// Css
import './index.css'
import './chart.css'
import './step.css'

/// Layout
import Nav from './layouts/nav'
import Footer from './layouts/Footer'

/// Dashboard
import Home from "./components/Dashboard/Home";
import MyProfile from "./components/Dashboard/MyProfile";
import Member from "./components/Dashboard/Member";
import Devices from "./components/Dashboard/Devices";
import History from "./components/Dashboard/History";
import WalletHistory from "./components/Dashboard/WalletHistory";
import Packs from "./components/Dashboard/Packs";
import SubAgents from "./components/Dashboard/SubAgents";
import SendMessage from "./components/Dashboard/SendMessage";
import Companies from "./components/Dashboard/Companies";
import Employee from "./components/Dashboard/Employee"; 
import Customers from "./components/Dashboard/Customers"; 
import CompaniesDetails from "./components/Dashboard/CompaniesDetails"; 
import DeviceDetails from "./components/Dashboard/DeviceDetails"; 
import Acknowledgement from "./components/Dashboard/Acknowledgement"; 

import Vouchers from './components/Dashboard/Vouchers';
import VoucherCodes from './components/Dashboard/VoucherCodes';

import AutoReply from './components/Dashboard/AutoReply';
import Template from './components/Dashboard/Template';
import WelcomeTemplate from './components/Dashboard/WelcomeTemplate';

import Messages from './components/Dashboard/Messages';

import ReceivedMessages from './components/Dashboard/ReceivedMessages';
import ApiMessages from './components/Dashboard/ApiMessages';
import Feedback from './components/Dashboard/Feedback';

import Api from './components/Dashboard/Api';

/// Pages
import Registration from './pages/Registration'
import Login from './pages/Login'
import ForgotPassword from './pages/forgot-password/forgot-password'
import LockScreen from './pages/LockScreen'
import Error400 from './pages/Error400'
import Error403 from './pages/Error403'
import Error404 from './pages/Error404'
import Error500 from './pages/Error500'
import Error503 from './pages/Error503'

//Scroll To Top
import ScrollToTop from './layouts/ScrollToTop';


const Markup = () => {
  let path = window.location.pathname
  path = path.split('/')
  path = path[path.length - 1]
  let pagePath = path.split('-').includes('page')
  const [activeEvent, setActiveEvent] = useState(!path)

  const routes = [
    /// Dashboard
    { url: '', component: Home },
    { url: 'dashboard', component: Home },
    { url: "my-profile", component: MyProfile },
    { url: "members", component: Member },
    { url: "devices", component: Devices },
    { url: "acks", component: Acknowledgement },
    { url: "companies", component: Companies },
    { url: "employees", component: Employee },
    { url: "company/:id", component: CompaniesDetails },
    { url: "device/:id", component: DeviceDetails },

    { url: "customers", component: Customers },
    { url: "history", component: History },
    { url: "wallet-history", component: WalletHistory },
    { url: "packs", component: Packs },
    { url: "sub-agents", component: SubAgents },
    { url: "send-message", component: SendMessage },
    { url: 'vouchers', component: Vouchers },
    { url: 'voucher-codes/:id/:name', component: VoucherCodes },
    { url: "auto-reply", component: AutoReply },
    { url: "templates", component: Template },
    { url: "welcome-template", component: WelcomeTemplate },
    { url: "messages", component: Messages },
    { url: "received-messages", component: ReceivedMessages },
    { url: "api-messages", component: ApiMessages },
    { url: "feedback", component: Feedback },
    { url: "api-documentation", component: Api },




    /// pages
    { url: 'page-register', component: Registration },
    { url: 'page-lock-screen', component: LockScreen },
    { url: 'page-login', component: Login },
    { url: 'forgot-password', component: ForgotPassword },
    { url: 'page-error-400', component: Error400 },
    { url: 'page-error-403', component: Error403 },
    { url: 'page-error-404', component: Error404 },
    { url: 'page-error-500', component: Error500 },
    { url: 'page-error-503', component: Error503 },
  ]

  return (
       <> 
          <div
            id={`${!pagePath ? 'main-wrapper' : ''}`}
            className={`${!pagePath ? 'show' : 'mh100vh'}`}
          >
            {!pagePath && (
              <Nav
                onClick={() => setActiveEvent(!activeEvent)}
                activeEvent={activeEvent}
                onClick2={() => setActiveEvent(false)}
                onClick3={() => setActiveEvent(true)}
              />
            )}
            <div
              className={` ${!path && activeEvent ? 'rightside-event' : ''} ${
                !pagePath ? 'content-body' : ''
              }`}
            >
              <div
                className={`${!pagePath ? 'container-fluid' : ''}`}
                style={{ minHeight: window.screen.height - 60 }}
              >
                <Switch>
                  {routes.map((data, i) => (
                    <Route
                      key={i}
                      exact
                      path={`/${data.url}`}
                      component={data.component}
                    />
                  ))}
                </Switch>
              </div>
            </div>
            {!pagePath && <Footer />}
          </div>
         <ScrollToTop />
       </>
  )
}

export default Markup
