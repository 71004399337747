import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ReactApexChart from "react-apexcharts";

import menu03 from "../../../images/menus/3.png";
import menu02 from "../../../images/menus/2.png";
import menu01 from "../../../images/menus/1.png";

import testimonial from "../../../images/testimonial/1.jpg";
import testimonial2 from "../../../images/testimonial/2.jpg";
import testimonial3 from "../../../images/testimonial/3.jpg";

import moment from "moment";

import {
  Spinner,
  Table,
  Pagination,
  Col,
  Row,
  Card,
  Badge,
  Dropdown,
  Tab,
  Nav,
  Button,
  Modal,
} from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";

import axiosInstance from "../../../services/AxiosInstance";

import { getProfile } from "../../../store/actions/AuthActions";

import Select from "react-select";
import stateData from "../../../utils/states.json";
// import Ext from "../../layouts/Ext";

import GoogleMap from "google-maps-react-markers";
import Marker from "./marker";
import swal from "sweetalert";
import { useRowState } from "react-table";

const Home = ({ history }) => {
  const dispatch = useDispatch();

  const [timeFilter, setTimeFilter] = useState("today");
  const [statistics, setStatistics] = useState(null);
  const profile = useSelector((state) => state.auth.profile);

  const [devices, setDevices] = useState([]);
  const [companies, setCompanies] = useState([]);

  const [filter, setFilter] = useState(false);

  const [chartLoading, setChartLoading] = useState(false);
  const chartRef = useRef();

  const [deviceModal, setDeviceModal] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState(null);

  const [chart, setChart] = useState([
    {
      name: "CUMULATIVE ENERGY GENERATED",
      field: "PTOTKWH1",
      show: true,
      suffix: "kWh",
    },

    {
      name: "CUMULATIVE RUNNIG Hrs",
      field: "PTOTHR1",
      show: false,
      suffix: "Hrs",
    },

    {
      name: "CUMULATIVE DISCHARGE",
      field: "POPTOTWD1",
      show: false,
      suffix: "Ltr",
    },
  ]);

  const colors = [
    "#008ffb",
    "#00e396",
    "#feb019",
    "#ff4560",
    "#775dd0",
    "#353f45",
    "#7b5446",
    "#ff5606",
  ];

  const timeFilterList = [
    {
      label: "Today",
      value: "today",
    },
    {
      value: "week",
      label: "This Week",
    },
    {
      value: "month",
      label: "This Month",
    },
    {
      value: "year",
      label: "This Year",
    },
  ];

  useEffect(() => {
    loadStatistics();
  }, [timeFilter]);

  const [selectedCompanyId, setSelectedCompanyId] = useState({
    value: "all",
    label: "All Company",
  });

  const [state, setState] = useState({
    value: "all",
    label: "All State",
  });
  const [district, setDistrict] = useState({
    value: "all",
    label: "All District",
  });

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      borderRadius: 0,
      background: "#fff",
      border: "1px solid #6e6e6e",
      color: "#6e6e6e",
      height: "56px",
    }),
    // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //   const color = chroma(data.color);
    //   return {
    //     ...styles,
    //     backgroundColor: isDisabled ? 'red' : blue,
    //     color: '#FFF',
    //     cursor: isDisabled ? 'not-allowed' : 'default',
    //   };
    // },
  };

  const mapRef = useRef(null);
  const [mapReady, setMapReady] = useState(false);

  const onGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
    setMapReady(true);
  };

  const onMarkerClick = (id) => {
    console.log("This is ->", id);

    const device = devices.find((x) => x.id == id);
    if (device) {
      swal(device.S_NO, {
        title: device.S_NO,
        text: "Status: " + device.status,
        buttons: {
          cancel: "Close",
          ok: {
            text: "View Details",
            value: "ok",
          },
        },
      }).then((value) => {
        if (value == "ok") {
          history.push("device/" + id);
        }
      });
    }
  };

  useEffect(() => {
    dispatch(getProfile());
    loadStatistics();
    loadCompanies();
    loadDevices();
  }, [dispatch]);

  useEffect(() => {
    loadDevices();
    loadStatistics();
  }, [selectedCompanyId, state, district]);

  const loadDevices = async () => {
    const { data } = await axiosInstance.post("admin/devices", {
      PAGE: 0,
      LIMIT: 9999999,
      SEARCH: "",
      DATA: false,
      company_id:
        selectedCompanyId.value != "all" ? selectedCompanyId.value : null,
      state: state.value != "all" ? state.value : null,
      district:
        district.value != "all" && state.value != "all" ? district.value : null,
    });

    if (data.status) {
      setDevices(data.devices);
    } else {
      setDevices([]);
    }
  };

  const loadCompanies = async () => {
    const { data } = await axiosInstance.post("admin/users", {
      role: 2,
      page: 0,
      limit: 99999999,
      search: "",
    });

    if (data.status) {
      setCompanies(data.users);
    } else {
      setCompanies([]);
    }
  };

  async function loadStatistics() {
    setChartLoading(true);
    try {
      const { data } = await axiosInstance.post("admin/statistics", {
        time_filter: timeFilter,
        company_id:
          selectedCompanyId.value != "all" ? selectedCompanyId.value : null,
        state: state.value != "all" ? state.value : null,
        district:
          district.value != "all" && state.value != "all"
            ? district.value
            : null,
      });

      console.log("statistics data - " + JSON.stringify(data));

      if (data.status === false) {
      } else {
        console.log("statistics", JSON.stringify(data));
        setStatistics(data);
      }
      setChartLoading(false);
    } catch (error) {
      setChartLoading(false);
    }
  }

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div className="owl-next" onClick={onClick} style={{ zIndex: 99 }}>
        <i className="fa fa-caret-right" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div
        className="owl-prev disabled"
        onClick={onClick}
        style={{ zIndex: 99 }}
      >
        <i className="fa fa-caret-left" />
      </div>
    );
  }

  const settings = {
    focusOnSelect: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1599,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const maximumValue = (array, field) => {
    return Math.max.apply(
      Math,
      array.map((a) => a[field])
    );
  };

  const minimumValue = (array, field) => {
    const valueArray = array.map((a) => a[field]);

    const min = Math.min.apply(Math, valueArray);
    //const max = Math.max.apply(Math,valueArray);

    const final = min > 0 ? Math.round(min * 0.5) : min;

    //console.log("minimumValue: field",field)
    //console.log("minimumValue: ",final)

    return final;
  };

  return (
    <>
      <Modal
        size={"md"}
        className="modal fade"
        show={deviceModal}
        onHide={setDeviceModal}
      >
        <div className="" role="document">
          <div className="">
            <div className="modal-header">
              <h4 className="modal-title fs-20">
                {selectedDevices?.title ?? ""}
              </h4>
              <button
                type="button"
                className="close"
                onClick={() => setDeviceModal(false)}
              >
                <span>×</span>
              </button>
            </div>

            <div className="modal-body">
              <Table>
                <thead>
                  <tr>
                    <th>S_NO</th>

                    <th>LAST UPDATE</th>

                    <th>STATE</th>

                    <th>DISTRICT</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedDevices == null ? (
                    <></>
                  ) : (
                    selectedDevices.data.map((x, i) => {
                      return (
                        <tr key={i}>
                          <td><Link to={"/device/"+x.id} >{x.S_NO}</Link></td>

                          <td>
                            {moment(new Date(x.timestamp))
                              .utc()
                              .format("DD-MM-YYYY hh:mm A")}
                          </td>

                          <td>{x.STATE}</td>

                          <td>{x.DISTRICT}</td>

                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                onClick={() => setDeviceModal(false)}
                className="btn btn-danger"
              >
                <i className="flaticon-delete-1"> </i>
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <div className="row d-none">
        <div className="col-md-4">

        <div className="form-group col-md-12">
              <label className="">Company</label>
              <Select
                class="form-select"
                value={selectedCompanyId}
                styles={colourStyles}
                onChange={(option) => setSelectedCompanyId(option)}
                options={[
                  {
                    value: "all",
                    label: "All Company",
                  },
                  ...companies.map((c) => {
                    return {
                      value: c.id + "",
                      label: c.name,
                    };
                  }),
                ]}
              ></Select>
            </div>

        </div>
        <div className="col-md-4">

        <div className="form-group">
              <label className="">State</label>
              <Select
                class="form-control"
                value={state}
                onChange={(option) => setState(option)}
                styles={colourStyles}
                options={[
                  {
                    value: "all",
                    label: "All State",
                  },

                  ...stateData.map((data) => {
                    return {
                      value: data.state,
                      label: data.state,
                    };
                  }),
                ]}
              ></Select>
            </div>


        </div>

        <div className="col-md-4">
        {state.value != "all" ? (
              <div className="form-group">
                <label className="">District</label>
                <Select
                  class="form-select"
                  value={district}
                  onChange={(option) => setDistrict(option)}
                  styles={colourStyles}
                  options={[
                    {
                      value: "all",
                      label: "All District",
                    },

                    ...(state.value != "all"
                      ? stateData
                          .find((x) => x.state == state.value)
                          .districts.map((data) => {
                            return {
                              value: data,
                              label: data,
                            };
                          })
                      : []),
                  ]}
                ></Select>
              </div>
            ) : (
              <></>
            )}

        </div>

      </div>

      <div className="row d-none">
     
      <div className="col-xl-2 col-xxl-2 col-lg-2 col-sm-2 col-md-2">
              <div className="widget-stat card">
                <div
                  className="card-body p-4"
                  onClick={(e) => {
                    setDeviceModal(true);
                    setSelectedDevices({
                      title: "System Installed",
                      data: devices,
                    });
                  }}
                >
                  <div className="media ai-icon">
                    <div className="media-body">
                    <label className="">System Installed</label>
                      <p className="mb-1"></p>
                      <h4 className="mb-0">{devices.length}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-2 col-xxl-2 col-lg-2 col-sm-2 col-md-2">
              <div
                className="widget-stat card"
                onClick={(e) => {
                  setDeviceModal(true);
                  setSelectedDevices({
                    title: "System Running",
                    data: devices.filter((x) => x.status == "RUN"),
                  });
                }}
              >
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <div className="media-body">
                    <label className="">System Running</label>
                      
                      <h4 className="mb-0">
                        {devices.filter((x) => x.status == "RUN").length}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-2 col-xxl-2 col-lg-2 col-sm-2 col-md-2">
              <div className="widget-stat card"
               onClick={(e) => {
                setDeviceModal(true);
                setSelectedDevices({
                  title: "System Switched Off",
                  data: devices.filter((x) => x.status == "STOP"),
                });
              }}
              >
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <div className="media-body">
                    <label className="">System Switched Off</label>
                      <h4 className="mb-0">
                        {devices.filter((x) => x.status == "STOP").length}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-2 col-xxl-2 col-lg-2 col-sm-2 col-md-2">
              <div className="widget-stat card"
               onClick={(e) => {
                setDeviceModal(true);
                setSelectedDevices({
                  title: "Needing Maintenance",
                  data: devices.filter((x) => x.status.startsWith("FAULT")),
                });
              }}
              >
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <div className="media-body">
                    <label className="">Needing Maintenance</label>
                      <h4 className="mb-0">
                        {
                          devices.filter((x) => x.status.startsWith("FAULT"))
                            .length
                        }
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-2 col-xxl-2 col-lg-2 col-sm-2 col-md-2">
              <div className="widget-stat card"
              
              onClick={(e) => {
                setDeviceModal(true);
                setSelectedDevices({
                  title: "Disconnected From MQTT",
                  data: devices.filter((x) => !x.mqtt_status),
                });
              }}
              >
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <div className="media-body">
                    <label className="">Disconnected From MQTT</label>
                      <h4 className="mb-0">
                        {devices.filter((x) => !x.mqtt_status).length}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-2 col-xxl-2 col-lg-2 col-sm-2 col-md-2">
              <div className="widget-stat card"
               onClick={(e) => {
                setDeviceModal(true);
                setSelectedDevices({
                  title: "System Disconnected",
                  data: devices.filter((x) =>
                  x.status.startsWith("DISCONNECTED")
                ),
                });
              }}
              >
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <div className="media-body">
                    <label className="">System Disconnected</label>
                      <h4 className="mb-0">
                        {
                          devices.filter((x) =>
                            x.status.startsWith("DISCONNECTED")
                          ).length
                        }
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

        </div>

         

          <div className="row d-none">
            <div className="col-12">
              <div className="widget-stat card">
                <div className="card-body p-0">
                  <div className="media ai-icon">
                    <div className="media-body">
                      <Row className="p-4">
                        <Col md="6" className="text-left">
                        <label className="">CHART</label>
                        </Col>

                        <Col md="6" className="text-right">
                          <Dropdown className="dropdown m-0 p-0">
                            <Dropdown.Toggle
                              variant=""
                              //as="button"
                              className="btn btn-sm rounded border border-light dropdown-toggle"
                            >
                              {
                                timeFilterList.find(
                                  (x) => x.value == timeFilter
                                )?.label
                              }
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-right">
                              {timeFilterList.map((x) => (
                                <Dropdown.Item
                                  key={x.value}
                                  onClick={(e) => setTimeFilter(x.value)}
                                >
                                  {x.label}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>

                      <div>
                        {statistics == null || chartLoading ? (
                          <div className="text-center py-4">
                            <Spinner animation="border" variant="primary" />
                          </div>
                        ) : (
                          <ReactApexChart
                            ref={chartRef}
                            options={{
                              chart: {
                                height: 350,
                                type: "line",
                                zoom: {
                                  enabled: false,
                                },
                                events: {
                                  legendClick: function (
                                    chartContext,
                                    seriesIndex,
                                    config
                                  ) {
                                    console.log("legend click", seriesIndex);
                                  },
                                },
                              },
                              dataLabels: {
                                enabled: false,
                              },
                              colors: colors,
                           

                              tooltip: {
                                onDatasetHover: {
                                  highlightDataSeries: true,
                                },
                                // x: {
                                //   show: true,
                                //   format: "dd-MM-yyyy HH:mm",
                                //   formatter: undefined,
                                // },
                              },

                              legend: {
                                show: false,
                                //showForSingleSeries: true,
                                //floating: true,
                              },
                              // title: {
                              //   text: "TIMESTAMP",
                              //   align: "left",
                              // },
                              markers: {
                                size: 0,
                              },
                              // xaxis: {
                              //   type: "datetime",
                              // },

                              xaxis: {
                                type: "text",
                                categories: statistics.chartData.map(
                                  (e) => e.label
                                ),
                                labels: {
                                  show: true,
                                  rotate: -90,
                                  style: {
                                    colors: [],
                                    fontSize: '10px',
                                    fontFamily: 'poppins, sans-serif',
                                    fontWeight: 400,
                                    cssClass: 'apexcharts-xaxis-label',
                                },
                                  }
                              },

                              stroke: {
                                curve: "straight",
                                show: chart.map((x, i) => true),
                                width: chart.map((x, i) => 2),
                              },
                              yaxis: chart.map((x, i) => {
                                return {
                                  min: x.min,
                                  //min:  x.min ? x.min : minimumValue(statistics.chartData,x.field),
                                  max: x.max,
                                  //tickAmount: 0.5,
                                  //logarithmic: true,
                                  showAlways: x.showAlways,
                                  //show: x.show ?? true,
                                  seriesName: x.name,
                                  tickAmount: x.tickAmount,
                                  opposite: i != 0,
                                  title: {
                                    text: x.name,
                                    rotate: -90,
                                    offsetX: 0,
                                    offsetY: 0,
                                    style: {
                                      colors: colors[i],
                                      fontSize: "10px",
                                      fontFamily: 'poppins, sans-serif',
                                      fontWeight: 300,
                                      cssClass: "apexcharts-yaxis-title",
                                    },
                                  },
                                  labels: {
                                    style: {
                                      colors: colors[i],
                                      fontSize: "10px",
                                      fontFamily: 'poppins, sans-serif',
                                      fontWeight: 300,
                                      cssClass: "apexcharts-yaxis-label",
                                    },
                                    formatter: (value) =>
                                      (value ?? 0.0).toFixed(2) +
                                      (x.suffix ? `${x.suffix}` : ""),
                                  },
                                };
                              }),
                            }}
                            series={chart.map((x) => {
                              return {
                                name: x.name,
                                data: statistics.chartData.map((d) => {
                                  return { x: d.label, y: d[x.field] };
                                }),
                              };
                            })}
                            type="line"
                            height={350}
                          />
                        )}
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
       

      <div className="row">
        <div className="col-12">
          <div className="widget-stat card">
            <div className="card-body p-0">
              <div className="media ai-icon">
                <div className="media-body">
                <label className="p-4">MAP</label>

                  <>
                    <GoogleMap
                      apiKey="AIzaSyCgaUL7e5aiZv_wxwG2mgUIvv1QkQbtPGM"
                      defaultCenter={{
                        lat: 28.6448,
                        lng: 77.216721,
                      }}
                      defaultZoom={5}
                      options={{
                        mapTypeControl: false,
                        fullscreenControl: false,
                        maxZoom: 20,
                        minZoom: 3,
                        rotateControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        mapTypeId: "roadmap",
                        zoomControl: true,
                      }}
                      mapMinHeight="100vh"
                      onGoogleApiLoaded={onGoogleApiLoaded}
                      onChange={(map) => console.log("Map moved", map)}
                    >
                      {devices.map((x) => {
                        var icon =
                          "https://img.icons8.com/fluency-systems-filled/30/FF0000/marker.png";

                          const status = x.status;

                        if (status == "RUN") {
                          icon =
                            "https://img.icons8.com/fluency-systems-filled/30/208420/marker.png";
                        } else if (status == "STOP") {
                          icon =
                            "https://img.icons8.com/fluency-systems-filled/30/FF0000/marker.png";
                        } else if (status == "DISCONNECTED") {
                          icon =
                            "https://img.icons8.com/fluency-systems-filled/30/797979/marker.png";
                        } else if (status.startsWith("FAULT")) {
                          icon =
                            "https://img.icons8.com/fluency/30/null/general-warning-sign.png";
                        } else {
                          icon =
                            "https://img.icons8.com/fluency-systems-filled/30/82C4E4/marker.png";
                        }

                        return (
                          <Marker
                            lat={x.LAT}
                            lng={x.LONG}
                            markerId={x.id}
                            src={icon}
                            onClick={onMarkerClick}
                          />
                        );
                      })}
                    </GoogleMap>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
