import Table from "react-bootstrap/Table";
import { axios } from "axios";
// import { useState ,useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Card from "react-bootstrap/Card";
import React, { useRef, useState, useEffect, Fragment } from "react";
import axiosInstance from "./../../../services/AxiosInstance";
import { Spinner } from "react-bootstrap";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
// import Sonnet from '../../components/Sonnet';

import { format } from "date-fns";
import { Link } from "react-router-dom";

import DTable from "../Table";

function CompaniesDetails({ match }) {
  const dispatch = useDispatch();

  const [activeToggle, setActiveToggle] = useState("devices");

  const [company, setCompany] = useState(null);
  const [customers, setcustomers] = useState([]);
  const [devices, setDevices] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [companies, setCompanies] = useState([]);

  // Data Table Start
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [10, 25, 50, 100];
  const [maxPage, setMaxPage] = useState(1);
  const [loading, setLoading] = useState(false);
  

  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  useEffect(() => {
    if (match.params.id) {
      loadCompanyDetails();
      loadTabData(true);
    }
  }, [dispatch]);


  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        console.log("search", "loadTabData");
        loadTabData(true);
      }, 1000)
    );

    return () => clearTimeout(searchTimeout);
  }, [search]);

  useEffect(() => {
    console.log("tab change", "loadTabData");
    loadTabData(true);
  }, [activeToggle]);

  const loadTabData = (reset) => {
    console.log("loadTabData:", reset);

    if (reset == true) {
      setPage(0);
      setPageSize(10);
      setMaxPage(1);
    }

    if (activeToggle == "devices") {
      loadDevices();
    } else if (activeToggle == "employees") {
      loadEmployees();
    } else if (activeToggle == "customers") {
      loadCustomers();
    }
  };


  //CompanyDetails
  const loadCompanyDetails = async () => {
    const { data } = await axiosInstance.get("admin/user/" + match.params.id);

    if (data.status) {
      setCompany(data.user);
      document.getElementById("nav_title").innerHTML = data.user.name
        ? data.user.name
        : data.user.email;
    } else {
      setCompany(null);
    }
  };

    //Devices
    const loadDevices = async () => {
      const { data } = await axiosInstance.post("admin/devices", {
        PAGE: page,
        LIMIT: pageSize,
        SEARCH: search,
        company_id: match.params.id,
      });
  
      if (data.status) {
        var maxPage2 = Math.ceil(data.total / pageSize);
        console.log("max page - " + maxPage2);
        setMaxPage(maxPage2);
        setLoading(false);
        setDevices(data.devices);
      }
    };
  //loadEmployees
  const loadEmployees = async () => {
    const { data } = await axiosInstance.post("admin/users", {
      role: 3,
      page: page,
      limit: pageSize,
      search: search,
      company_id: match.params.id,
    });

    if (data.status) {
      var maxPage2 = Math.ceil(data.total / pageSize);
      console.log("max page - " + maxPage2);
      setMaxPage(maxPage2);
      setLoading(false);
      setEmployees(data.users);
    } else {
      setEmployees([]);
    }
  };
  const companyName = (companyId) => {
    const company = companies.find((c) => c.id === companyId);
    if (company) {
      return company.name;
    } else {
      return "No";
    }
  };
  //LoadCustomer
  const loadCustomers = async () => {
    const { data } = await axiosInstance.post("admin/users", {
      role: 4,
      page: page,
      limit: pageSize,
      search: search,
      company_id: match.params.id,
    });

    if (data.status) {
      var maxPage2 = Math.ceil(data.total / pageSize);
      console.log("max page - " + maxPage2);
      setMaxPage(maxPage2);
      setLoading(false);
      setcustomers(data.users);
    } else {
      setcustomers([]);
    }
  };

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(0);
  };

  useEffect(() => {
    loadTabData(false);
  }, [page, pageSize]);

  const columnsCustomers = [
    {
      Header: "No",
      accessor: "",
      sort: false,
      accessor: "",

      Cell: (row) => {
        return <div>{Number(row.row.id) + page * pageSize + 1}</div>;
      },
    },

    {
      Header: "ID",
      accessor: "id",
      sort: false,
    },

    {
      Header: "Name",
      accessor: "name",
      sort: false,
    },

    {
      Header: "Email",
      accessor: "email",
      sort: false,
    },
    {
      Header: "Phone",
      accessor: "phone",
      sort: false,
    },

    {
      Header: "CREATED_AT",
      accessor: "createdAt",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.createdAt
          ? format(new Date(d.createdAt), "dd/MM/yyyy H:mma")
          : "No";
      },
    },
  ];
  //Devices
  const columnsDevices = [
    {
      Header: "No",
      accessor: "",
      sort: false,
      accessor: "",

      Cell: (row) => {
        return <div>{Number(row.row.id) + page * pageSize + 1}</div>;
      },
    },

    {
      Header: "S_NO",
      accessor: "S_NO",
      sort: false,
      Cell: ({ row }) => {
        const d = row.original;
        return <Link to={"/device/"+d.id} >{d.S_NO}</Link>;
        
      },
    },

    {
      Header: "IMEI",
      accessor: "IMEI",
      sort: false,
    },
    {
      Header: "ASN_11",
      accessor: "ASN_11",
      sort: false,
    },

    {
      Header: "CREATED_AT",
      accessor: "createdAt",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.createdAt
          ? format(new Date(d.createdAt), "dd/MM/yyyy H:mma")
          : "No";
      },
    },
  ];

  //Employee
  const columnsEmplyee = [
    {
      Header: "No",
      accessor: "",
      sort: false,
      accessor: "",

      Cell: (row) => {
        return <div>{Number(row.row.id) + page * pageSize + 1}</div>;
      },
    },

    {
      Header: "ID",
      accessor: "id",
      sort: false,
    },

    {
      Header: "Name",
      accessor: "name",
      sort: false,
    },

    {
      Header: "Company",
      accessor: "company_id",
      sort: false,
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <Link to={"/company/" + d.company_id}>
            {companyName(d.company_id)}
          </Link>
        );
      },
    },
    {
      Header: "Email",
      accessor: "email",
      sort: false,
    },
    {
      Header: "Phone",
      accessor: "phone",
      sort: false,
    },

    {
      Header: "CREATED_AT",
      accessor: "createdAt",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.createdAt
          ? format(new Date(d.createdAt), "dd/MM/yyyy H:mma")
          : "No";
      },
    },
  ];
  return (
    <>
      <Card style={{ height: "210px", marginTop: "-35px" }}>
        <Card.Body>
          {company ? (
            <Table>
              <thead>
                <tr className="row">
                  <th className="col-3">Name</th>
                  <th className="col-3">Email</th>
                  <th className="col-3">Phone</th>
                  <th className="col-3">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr className="row">
                  <td className="col-3">{company.name}</td>
                  <td className="col-3">{company.email}</td>
                  <td className="col-3">{company.phone}</td>
                  <td className="col-3"></td>
                </tr>
              </tbody>
            </Table>
          ) : (
            <Spinner />
          )}
        </Card.Body>
      </Card>

      <Card>
      
      
      <Card.Body><Tabs
        defaultActiveKey={activeToggle}
        id="uncontrolled-tab-example"
        className="mb-3"
        activeKey={activeToggle}
        onSelect={(k) => setActiveToggle(k)}
        justify
      >
        <Tab eventKey="devices" title="Devices">
          {devices.length == 0 ? (
            <p>No Device Found</p>
          ) : (
            <DTable
              columns={columnsDevices}
              data={devices}
              pageSize={pageSize}
              isSortable={true}
              pagination={false}
              pageSizes={pageSizes}
              page={page}
              maxPage={maxPage}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
            />
          )}
        </Tab>
        <Tab eventKey="employees" title="Employees">
          {employees.length == 0 ? (
            <p>No Employee Found</p>
          ) : (
            <DTable
              columns={columnsEmplyee}
              data={employees}
              pageSize={pageSize}
              isSortable={true}
              pagination={false}
              pageSizes={pageSizes}
              page={page}
              maxPage={maxPage}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
            />
          )}
        </Tab>
        <Tab eventKey="customers" title="Customers">
          <Card>
            <Card.Body>
              {customers.length == 0 ? (
                <p>No customer Found</p>
              ) : (
                <DTable
                  columns={columnsCustomers}
                  data={customers}
                  pageSize={pageSize}
                  isSortable={true}
                  pagination={false}
                  pageSizes={pageSizes}
                  page={page}
                  maxPage={maxPage}
                  handlePageChange={handlePageChange}
                  handlePageSizeChange={handlePageSizeChange}
                />
              )}
            </Card.Body>
          </Card>
        </Tab>
        
      </Tabs>
      </Card.Body>
      </Card>
    </>
  );
}

export default CompaniesDetails;
