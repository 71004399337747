import React,{ useState,useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import {
    loadingToggleAction,
    loginAction,
    loginFailedAction,
  } from "../../store/actions/AuthActions";
// image
//import logo from "../../images/logo-text.png";
//import loginbg from "../../images/login-bg-1.jpg";
import loginbg from "../../images/login-bg-4.jpg";
//import loginbg from "../../images/login-bg-1.jpg";

import logo from "../../images/logo-transparent.png";

import { useAuth, auth, firebase } from "../../providers/use-auth";

import swal from "sweetalert";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import { Spinner } from "react-bootstrap";

function Login (props) {
    const [email, setEmail] = useState("");
    let errorsObj = { email: "", password: "" };
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState("");
  
    const [phone, setPhone] = useState("");
    const [otp, setotp] = useState("");
    const [showOtp, setshowOtp] = useState(false);
    const [final, setfinal] = useState("");
  
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
  
    //firebase
    const fAuth = useAuth();
  
    useEffect(async () => {
      dispatch(loginFailedAction(null));
    }, [dispatch]);


    const onLogin = async (e) => {
        //function onLogin(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (email === "") {
          errorObj.email = "Email is Required";
          error = true;
        }
        if (password === "") {
          errorObj.password = "Password is Required";
          error = true;
        }
        setErrors(errorObj);
        if (error) {
          return;
        }
        dispatch(loadingToggleAction(true));
    
        let response = await fAuth.signin(email, password);
        if (response.hasOwnProperty("message")) {
          console.log("sign in error");
          console.log(response.message);
          dispatch(loadingToggleAction(true));
          dispatch(loginFailedAction(response.message));
          return;
        }
    
        if (response.hasOwnProperty("user")) {
          console.log(response.user);
          //dispatch(signIn(response.user,"email"));
          const idToken = await response.user.getIdToken(true);
          dispatch(
            loginAction(
              idToken,
              response.user.displayName,
              response.user.email,
              props.history
            )
          );
          return;
        }
    
        //dispatch(loginAction(email, password, props.history));
      };
    
      function setuprecaptcha() {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: (response) => {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              // ...
              signin();
            },
            "expired-callback": () => {
              // Response expired. Ask user to solve reCAPTCHA again.
              // ...
            },
          }
        );
      }
    
      // Sent OTP
      const signin = (e) => {
        e.preventDefault();
    
        let error = false;
        const errorObj = { ...errorsObj };
    
        if (phone === "" || phone.length < 10) {
          errorObj.phone = "Phone Number is Required";
          error = true;
        }
    
        setErrors(errorObj);
        if (error) {
          return;
        }
    
        //let appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
    
        setLoading(true);
    
        if(!window.recaptchaVerifier){
          setuprecaptcha();
        }
        
        const appVerifier = window.recaptchaVerifier;
    
        firebase
          .auth()
          .signInWithPhoneNumber(phone, appVerifier)
          .then((result) => {
            setfinal(result);
            console.log(result);
            setLoading(false);
            //alert("code sent")
            swal(
              "Phone Login",
              "One Time Password sent on your phone number " + phone,
              "success"
            );
            setshowOtp(true);
          })
          .catch((err) => {
            //alert(err);
            setLoading(false);
            console.log("error -" + err);
            swal("Phone Login", err.message, "error");
            window.location.reload();
          });
      };
    
      // Validate OTP
      const ValidateOtp = (e) => {
        e.preventDefault();
    
        let error = false;
        const errorObj = { ...errorsObj };
    
        if (otp === null || final === null) {
          errorObj.phone = "OTP is Required";
          error = true;
        }
    
        setErrors(errorObj);
        if (error) {
          return;
        }
    
        setLoading(true);
        final
          .confirm(otp)
          .then(async (result) => {
            // success
            setLoading(false);
            if (result.hasOwnProperty("user")) {
              console.log(result.user);
    
              //dispatch(signIn(response.user,"email"));
              const idToken = await result.user.getIdToken(true);
              dispatch(
                loginAction(
                  idToken,
                  result.user.displayName ?? "",
                  result.user.email ?? "",
                  props.history
                )
              );
              return;
            }
          })
          .catch((err) => {
            //alert("Wrong code");
            setLoading(false);
            swal("Phone Login", "Wrong One Time Password", "error");
          });
      };
  

  return (
		<div className="login-main-page" style={{backgroundImage:"url("+ loginbg +")"}}>
            <div className="login-wrapper">
                <div className="login-aside-left" >
                
                    <div className="login-description">

                    <img class="img img-fluid mb-5" width={200} src={logo} alt=""/>

                        <h2 className="main-title mb-2 mt-5">Welcome To Kisan Solar</h2>
                        <p className=""></p>
                        <ul className="social-icons mt-4">
                            <li><Link to={"#"}><i className="fa fa-facebook"></i></Link></li>
                            <li><Link to={"#"}><i className="fa fa-twitter"></i></Link></li>
                            <li><Link to={"#"}><i className="fa fa-linkedin"></i></Link></li>
                        </ul>
                        <div className="mt-3 bottom-privacy">
                            <p>Copyright © Designed & Developed by <a href="https://kisansolar.com/" rel="noreferrer" target="_blank">kisansolar.com</a> 2022</p>
                        </div>
                    </div>
                </div>
                <div className="login-aside-right">
                    <div className="row m-0 justify-content-center h-100 align-items-center">
                      <div className="p-5">
                        <div className="authincation-content">
                          <div className="row no-gutters">
                            <div className="col-xl-12">
                              <div className="auth-form-1">
                                <div className="mb-4">
                                    <h3 className="dz-title mb-1">Admin Sign in</h3>
                                    <p className="">Sign in by entering information below</p>
                                </div>
                                {props.errorMessage && (
                                    <div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
                                        {props.errorMessage}
                                    </div>
                                )}
                                {props.successMessage && (
                                    <div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
                                        {props.successMessage}
                                    </div>
                                )}


{!showOtp ? (
                  <form onSubmit={signin} className="mt-4">
                    <div className="form-group">
                      <label className="mb-2 ">
                        <strong>Phone Number</strong>
                      </label>
                    

                      <PhoneInput
                        placeholder="Enter phone number"
                        value={phone}
                        defaultCountry="IN"
                        onChange={setPhone}
                      />

                      {errors.phone && (
                        <div className="text-danger fs-12">{errors.phone}</div>
                      )}
                    </div>

                    <div className="text-center mt-4">
                      {loading ? (
                        <Spinner animation="border" variant="primary" />
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          Send OTP
                        </button>
                      )}
                    </div>

                    {props.errorMessage && (
                      <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2 text-danger">
                        {props.errorMessage}
                      </div>
                    )}
                    {props.successMessage && (
                      <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                        {props.successMessage}
                      </div>
                    )}
                  </form>
                ) : (
                  <form onSubmit={ValidateOtp} className="">
                    <div className="form-group">
                      <Link
                        className="text-primary"
                        onClick={(e) => {
                          setfinal(null);
                          setshowOtp(false);
                        }}
                      >
                        Change Number
                      </Link>

                      <br />

                      <label className="my-2">
                        <strong>One Time Password</strong>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        value={otp}
                        onChange={(e) => setotp(e.target.value)}
                        placeholder="Enter OTP"
                      />

                      {errors.otp && (
                        <div className="text-danger fs-12">{errors.otp}</div>
                      )}
                    </div>

                    <div className="text-center mt-4">
                      {loading ? (
                        <Spinner animation="border" variant="primary" />
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          Verify
                        </button>
                      )}
                    </div>

                    {props.errorMessage && (
                      <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2 text-danger">
                        {props.errorMessage}
                      </div>
                    )}

                    {props.successMessage && (
                      <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                        {props.successMessage}
                      </div>
                    )}
                  </form>
                )}


<div id="recaptcha-container"></div>
                              
                              
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
	);
};

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(Login);
