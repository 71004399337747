import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";

///
//  import drump from "../../../images/card/drump.png";

class MM extends Component {
   componentDidMount() {
      this.$el = this.el;
      this.mm = new MetisMenu(this.$el);
   }
   componentWillUnmount() {
      // this.mm("dispose");
      // console.log(this.mm);
   }
   render() {
      return (
         <div className="mm-wrapper">
            <ul className="metismenu" ref={(el) => (this.el = el)}>
               {this.props.children}
            </ul>
         </div>
      );
   }
}

class SideBar extends Component {
   /// Open menu
   componentDidMount() {
      // sidebar open/close
      var btn = document.querySelector(".nav-control");
      var aaa = document.querySelector("#main-wrapper");

      function toggleFunc() {
         return aaa.classList.toggle("menu-toggle");
      }

      btn.addEventListener("click", toggleFunc);   
   }
   render() {
      /// Path
      let path = window.location.pathname;
      path = path.split("/");
      path = path[path.length - 1];

      /// Active menu
      // let deshBoard = [
      //        "",
      //    ],
      //    campaign = [
      //       "voucher",
      //    ];
       
      return (
         <div className="deznav">
            <PerfectScrollbar className="deznav-scroll">
               <MM className="metismenu" id="menu">
                 


<li className={`${path === "" ? "mm-active" : ""}`}>
                     <Link
                        to="/"
                        className="ai-icon"
                        
                     >
                        
                        <i className="flaticon-381-home-2"></i>
                        <span className="nav-text">Dashboard</span>
                     </Link>
                  </li>


                  <li className={`${path === "devices" ? "mm-active" : ""}`}>
                     <Link
                        to="/devices"
                        className="ai-icon"
                        
                     >
                        
                        <i className="flaticon-381-smartphone-5"></i>
                        <span className="nav-text">Devices</span>
                     </Link>
                  </li>

                  <li className={`${path === "acks" ? "mm-active" : ""}`}>
                     <Link
                        to="/acks"
                        className="ai-icon"
                        
                     >
                        
                        <i className="flaticon-381-smartphone-5"></i>
                        <span className="nav-text">Acks</span>
                     </Link>
                  </li>
                  <li className={`${path === "companies" ? "mm-active" : ""}`}>
                     <Link
                        to="/companies"
                        className="ai-icon"
                        
                     >
                        
                        <i className="flaticon-381-user-7"></i>
                        <span className="nav-text">Companies</span>
                     </Link>
                  </li>
                  <li className={`${path === "employees" ? "mm-active" : ""}`}>
                     <Link
                        to="/employees"
                        className="ai-icon"
                        
                     >
                        
                        <i className="flaticon-381-user-7"></i>
                        <span className="nav-text">Employees</span>
                     </Link>
                  </li>
                  <li className={`${path === "customers" ? "mm-active" : ""}`}>
                     <Link
                        to="/customers"
                        className="ai-icon"
                        
                     >
                        
                        <i className="flaticon-381-user-7"></i>
                        <span className="nav-text">Customers</span>
                     </Link>
                  </li>
               </MM>
              

               <div className="copyright">
                  <p>
                     <strong>Kisan Solar Admin</strong> ©All Rights Reserved
                  </p>
                  <p>By kisansolar.com</p>
               </div>
            </PerfectScrollbar>
         </div>
      );
   }
}

export default SideBar;
